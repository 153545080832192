.white-popup.mfp-with-anim#social-login-popup{
    max-width: 380px;
    .social-login.block-container {
        .social-login-title {
            background-color: white !important;
            padding: 25px;
            text-align: center;
            .create-account-title, .forgot-pass-title {
                background-image: none;
                font-weight: 600;
                font-size: 20px;
                text-transform: uppercase;
                color: #000000;
                padding-left: 0;
            }
        }
        .block-content {
            .form-customer-create, .form-customer-login {
                .fieldset {
                    .control {
                        width: 100%;
                    }
                    .field {
                        margin-bottom: 10px;

                    }
                }
                .actions-toolbar {
                    .action.create.primary {
                        margin-bottom: 26px;
                    }
                    .primary, .secondary {
                        text-align: center;
                        width: 100% !important;
                        .action.back, .action.remind, .action.login.create {
                            font-size: 12px;
                            line-height: 100%;
                            text-decoration-line: underline;
                            color: #000000;
                        }
                    }

                }
            }
        }
    
    }

}
