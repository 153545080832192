//
//  Variables
//  _____________________________________________

$active-nav-indent: 54px !default;

.header.content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px !important;
    flex-direction: row;
    flex-wrap: wrap;

    .ammenu-logo {
        display: inline-flex;
        margin: 0 auto;
        height: 65px;      
        width: 165px;  
        text-align: center;
        justify-content: center;    
        @include max-screen($screen__m) {
            height: 50px;
        }
    }
    .top-icons {
        @media(max-width:769px) {
            display: none;
        }
    }

    .top-icons,
    .minicart-wrapper {
        margin-left: 30px;
        @media(max-width:769px) {
            margin-left: 0;
        }

        a.action.showcart, a.wishlist-custom, a.social-login-btn, a.store-link {
            display: block !important;
            position: relative;
            font-weight: 300;
            font-size: 14px;
            line-height: 17px;
            color: $header-icons-color;
            text-align: center;
            text-decoration: none;
            overflow: hidden;
            transition: all ease 0.3s;
            min-width: 30px;
            min-height: 30px;
            &::before {
                content: "" !important;
                display: block !important;
                background-position: center;
                background-repeat: no-repeat;
                height: 30px;
                margin: 0 auto;
                padding-bottom: 10px;
                transition: all ease 0.3s;
            }

            &::after {
                content: "";
                display: block;
                margin-top: 6px;
                height: 2px;
                background: #dda982;
                transform: translateX(-101%);
                transition: all ease 0.3s;
            }

            &:hover {
                color: $header-icons-color-hover;

                &::before {
                    opacity: 0.6;
                }

                &::after {
                    transform: translateX(0);
                }
            }
        }
    }

    .top-icons {
        &.store-link {
            margin: 0;
            order: 2;
            a:before {
                background-image: url("../images/icons/stores.svg");
            }
            @include max-screen($screen__m) {
                display: none;
            }
        }

        &.my-account-link {
            order: 3;
            a:before {
                background-image: url("../images/icons/account.svg");
            }
            @include max-screen($screen__m) {
                display: none;
            }
        }

        &.custom.link.wishlist {
            order: 4;
            a {
                &:before {
                    background-image: url("../images/icons/wishlist.svg");
                }
                .counter.qty {
                    position: absolute;
                    bottom: 25px;
                    left: 50%;
                    width: 16px;
                    height: 16px;
                    min-width: 16px;
                    margin: 21px 0 0;
                    padding: 0;
                    background: #bb0339;
                    border-radius: 50%;
                    line-height: 16px;
                    font-size: 8px;
                    overflow: hidden;
                    color: white;
                }
            }

            @include max-screen($screen__m) {
                display: none;
            }
        }
    }

    .minicart-wrapper {
        order: 5;
        margin-top: 0;

        span.text {
            display: block;
            position: relative;
            height: auto;
            width: auto;

            @include max-screen($screen__m) {
                display: none;
            }
        }

        a.action.showcart {
            &:before {
                background-image: url("../images/icons/cart.svg");
                width: 25px;
            }
            .counter.qty {
                position: absolute;
                bottom: 22px;
                left: 50%;
                width: 16px;
                height: 16px;
                min-width: 16px;
                margin: 21px 0 0;
                padding: 0;
                background: #bb0339;
                border-radius: 50%;
                line-height: 16px;
                font-size: 8px;
                overflow: hidden;
                .loading-mask {
                    background: #bb0339;
                    .loader {
                        width: 22px;
                        height: 22px;
                        margin-left: -7px;
                        margin-top: -7px;
                        background-size: 135%;
                        background-image: url("data:image/svg+xml,%3Csvg version='1.1' id='L9' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 100 100' enable-background='new 0 0 0 0' xml:space='preserve'%3E%3Cpath fill='%23fff' d='M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50'%3E%3CanimateTransform attributeName='transform' attributeType='XML' type='rotate' dur='1s' from='0 50 50' to='360 50 50' repeatCount='indefinite'%3E%3C/animateTransform%3E%3C/path%3E%3C/svg%3E");
                        > img {
                            display: none;
                        }
                    }
                }
            }
        }
    }

    .block-search {
        order: 0;
        @include max-screen($screen__m) {
            display: block;
            flex-basis: 100%;
            /*flex-grow: 1;*/
            order: 10;
            margin: 0 auto;
            margin-top: 15px;
        }
    }
}

.panel.header {
    .links,
    .switcher {
        display: none;
    }
}

.nav-sections {
    @include lib-css(background, $navigation__background);
}

.nav-toggle {
    @include lib-icon-font(
        $icon-menu,
        $_icon-font-size: 28px,
        $_icon-font-color: $header-icons-color,
        $_icon-font-color-hover: $header-icons-color-hover
    );
    @include lib-icon-text-hide();
    cursor: pointer;
    display: block;
    font-size: 0;
    left: 15px;
    position: absolute;
    top: 15px;
    z-index: 14;
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {
    .navigation {
        padding: 0;

        .parent {
            .level-top {
                position: relative;
                @include lib-icon-font(
                    $_icon-font-content: $icon-down,
                    $_icon-font-size: 42px,
                    $_icon-font-position: after,
                    $_icon-font-display: block
                );

                &:after {
                    position: absolute;
                    right: 7px;
                    top: -8px;
                }

                &.ui-state-active {
                    @include lib-icon-font-symbol($_icon-font-content: $icon-up, $_icon-font-position: after);
                }
            }
        }
    }

    .nav-sections {
        -webkit-overflow-scrolling: touch;
        @include lib-css(transition, left 0.3s, 1);
        height: 100%;
        left: -80%;
        left: calc(-1 * (100% - #{$active-nav-indent}));
        overflow: auto;
        position: fixed;
        top: 0;
        width: 80%;
        width: calc(100% - #{$active-nav-indent});

        .switcher {
            border-top: 1px solid $color-gray82;
            font-size: 1.6rem;
            font-weight: 700;
            margin: 0;
            padding: 0.8rem 3.5rem 0.8rem 2rem;

            .label {
                display: block;
                margin-bottom: $indent__xs;
            }

            &:last-child {
                border-bottom: 1px solid $color-gray82;
            }
        }

        .switcher-trigger {
            strong {
                position: relative;
                @include lib-icon-font(
                    $_icon-font-content: $icon-down,
                    $_icon-font-size: 42px,
                    $_icon-font-position: after,
                    $_icon-font-display: block
                );

                &:after {
                    position: absolute;
                    right: -40px;
                    top: -16px;
                }
            }

            &.active strong {
                @include lib-icon-font-symbol($_icon-font-content: $icon-up, $_icon-font-position: after);
            }
        }

        .switcher-dropdown {
            @include lib-list-reset-styles();
            padding: $indent__s 0;
        }

        .header.links {
            @include lib-list-reset-styles();
            border-bottom: 1px solid $color-gray82;

            li {
                font-size: 1.6rem;
                margin: 0;

                &.greet.welcome {
                    border-top: 1px solid $color-gray82;
                    font-weight: 700;
                    padding: 0.8rem $indent__base;
                }

                > a {
                    border-top: 1px solid $color-gray82;
                }
            }

            a,
            a:hover {
                @include lib-css(color, $navigation-level0-item__color);
                @include lib-css(text-decoration, $navigation-level0-item__text-decoration);
                display: block;
                font-weight: 700;
                padding: 0.8rem $indent__base;
            }

            .header.links {
                border: 0;
            }
        }
    }

    .nav-before-open {
        height: 100%;
        overflow-x: hidden;
        width: 100%;

        .page-wrapper {
            @include lib-css(transition, left 0.3s, 1);
            height: 100%;
            left: 0;
            overflow: hidden;
            position: relative;
        }

        body {
            height: 100%;
            overflow: hidden;
            position: relative;
            width: 100%;
        }
    }

    .nav-open {
        .page-wrapper {
            left: 80%;
            left: calc(100% - #{$active-nav-indent});
        }

        .nav-sections {
            $_shadow: 0 0 5px 0 rgba(50, 50, 50, 0.75);

            @include lib-css(box-shadow, $_shadow, 1);
            left: 0;
            z-index: 99;
        }

        .nav-toggle {
            &:after {
                background: rgba(0, 0, 0, $overlay__opacity);
                content: "";
                display: block;
                height: 100%;
                position: fixed;
                right: 0;
                top: 0;
                width: 100%;
                z-index: 1;
            }
        }
    }

    .nav-sections-items {
        @include lib-clearfix();
        position: relative;
        z-index: 1;
    }

    .nav-sections-item-title {
        background: darken($navigation__background, 5%);
        border: solid darken($navigation__background, 10%);
        border-width: 0 0 1px 1px;
        box-sizing: border-box;
        float: left;
        height: 71px;
        padding-top: 24px;
        text-align: center;
        width: 33.33%;

        &.active {
            background: transparent;
            border-bottom: 0;
        }

        .nav-sections-item-switch {
            &:hover {
                text-decoration: none;
            }
        }
    }

    .nav-sections-item-content {
        @include lib-clearfix();
        box-sizing: border-box;
        float: right;
        margin-left: -100%;
        margin-top: 71px;
        width: 100%;

        &.active {
            display: block;
        }

        padding: $indent__m 0;
    }

    @include lib-main-navigation();
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .nav-toggle {
        display: none;
    }

    .nav-sections {
        @include lib-vendor-prefix-flex-shrink(0);
        @include lib-vendor-prefix-flex-basis(auto);
        margin-bottom: $indent__m;
    }

    .nav-sections-item-title {
        display: none;
    }

    .nav-sections-item-content {
        display: block !important;
    }

    .nav-sections-item-content > * {
        display: none;
    }

    .nav-sections-item-content {
        > .navigation {
            display: block;
        }
    }

    @include lib-main-navigation-desktop();

    .panel.header {
        .links,
        .switcher {
            display: inline-block;
        }
    }
}
