.catalog-product-view {
    .page-title-wrapper.product {
        height: auto;
        background: none;
        display: block;
        margin-bottom: 0;
    }

    .a2a_menu.a2a_mini {
        .a2a_mini_services {
            a.a2a_i {
                color: #000000;
            }
        }

        .a2apage_wide.a2a_wide {
            display: none;
        }
    }

    .product-info-main {
        .brand-container {
            .brand-title {
                font-weight: 500;
                font-size: 14px;
                text-transform: uppercase;
                margin: 0 0 16px;
            }
        }

        .page-title-wrapper.product {
            .amshopby-option-link {
                display: none;
            }

            .page-title {
                font-weight: bold;
                font-size: 20px;
                margin-bottom: 14px;
            }
        }
        .product-info-price {
            margin-bottom: 22px;
        }

        .product.attribute.overview {
            margin: 0;

            .value {
                font-weight: 400;
                font-size: 16px;
                margin-bottom: 22px;
            }
        }

        .product-info-price {
            .price-box {
                &:before {
                    content: "Prix";
                    margin-bottom: 14px;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 110%;
                    text-transform: capitalize;
                    display: block;
                }

                .price-label {
                    display: none !important;
                }

                .normal-price {
                    font-size: 24px;
                    line-height: 110%;
                    display: inline;
                    margin-right: 10px;
                }
                .old-price {
                   .price-container {
                       .price-wrapper {
                           .price {
                               font-size: 14px;
                           }
                       }
                   } 
                }
            }

            .product-info-stock-sku {
                .availability.only.configurable-variation-qty {
                    display: none !important;
                }
            }
        }

        .product-options-wrapper {
            .swatch-opt {
                margin: 0;

                .swatch-attribute-label {
                    display: none;
                }

                .swatch-attribute {

                    .attribute-selected-option,
                    .swatch-attribute-selected-option {
                        font-weight: 600;
                        font-size: 16px;
                        display: inline-block;
                        margin-bottom: 10px;
                        padding-left: 0;
                        color: #000000;
                    }

                    .swatch-option.selected {
                        outline: none;
                    }

                    .swatch-attribute-options {
                        margin: 0;

                        .swatch-option {
                            &.selected {
                                pointer-events: none;
                                box-shadow: 0 0 8px black;
                            }

                            background: #ffffff;
                            border: 1px solid #000000;
                            box-sizing: border-box;
                            border-radius: 3px;
                            min-width: 76px;
                            min-height: 38px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            font-weight: bold;
                            font-size: 15px;
                            text-transform: lowercase;
                            color: #000000;

                            &:not(.disabled) {
                                &:hover {
                                    outline: none;
                                    box-shadow: 0 0 3px #999;
                                }
                            }
                        }
                    }
                }
            }
        }

        .box-tocart {
            width: 100%;

            .add-qty-text {
                font-weight: 600;
                font-size: 15px;
                text-transform: capitalize;
            }

            .field.qty {
                .control {
                    display: flex;
                    padding-top: 20px;

                    .input-text.qty {
                        border: none;
                        font-weight: 600;
                    }

                    .qty-update {
                        font-size: 30px;
                        background: white;
                        border: 1px solid #c4c4c4;
                        box-sizing: border-box;
                        border-radius: 3px;
                        width: 45px;
                        height: 45px;
                    }

                    .actions {
                        display: flex;

                        .action.primary.tocart {
                            width: 369px;
                            height: 45px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            border-radius: 3px;

                            @media(max-width:769px) {
                                width: 100%;
                                padding: 0 65px;
                            }

                            span {
                                font-size: 14px;
                                text-transform: uppercase;
                            }
                        }
                    }
                }
            }
        }

        .product-social-links {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 24px;
            width: 100%;
            .mp_social_share_inline_under_cart {
                padding: 0;

                .a2a_button_facebook,
                .a2a_button_facebook_messenger,
                .a2a_button_pinterest {
                    display: none;
                }

                .a2a_dd {
                    .a2a_svg.a2a_s__default.a2a_s_a2a {
                        background: black url(/media/catalog/social-share.png) no-repeat center;
                        padding: 25px 32px;
                        border-radius: 3px !important;
                        height: 45px;

                        svg {
                            display: none;
                        }
                    }
                }
            }

            .product-addto-links {
                .action.wishlist {
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 14px;
                    text-transform: uppercase;
                    &:before {
                        content: url(/media/catalog/heart.png);
                        padding-right: 10px;
                    }                    
                }
            }
        }

        .widget.block.block-static-block {
            .divider {
                margin-inline-start: 0;
                border-top: 1px solid #000000;

                @media (max-width: 426px) {
                    display: block;
                }
            }

            .product-view.widget-info {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;

                .first-text-product,
                .second-text-product,
                .third-text-product {
                    padding-right: 0;

                    h3 {
                        display: flex;
                        width: min-content;
                        font-weight: 300;
                        font-size: 14px;
                        line-height: 17px;
                        align-items: center;
                        align-content: center;
                    }
                }

                .first-text-product {
                    h3 {
                        &:before {
                            content: url(/media/catalog/credit-card-lock-2.png);
                            padding-right: 15px;
                        }
                    }
                }

                .second-text-product {
                    h3 {
                        &:before {
                            content: url(/media/catalog/delivery-truck-clock-3.png);
                            padding-right: 15px;
                        }
                    }
                }

                .third-text-product {
                    h3 {
                        &:before {
                            content: url(/media/catalog/Group-3749.png);
                            padding-right: 15px;
                        }
                    }
                }
            }
        }
    }

    .product.media {
        .fotorama__wrap {
            margin: auto;

            .fotorama__thumb-border {
                border: 1px solid #000000;
            }

            .fotorama__dot {
                background-color: #dda982;
                width: 8px;
                height: 8px;
                border-color: #dda982;
            }
        }
    }
}