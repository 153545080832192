//Footer Block
.social-links {
    display: flex;
    justify-content: center;
    width: 100%;
    margin: 50px auto;
    flex-wrap: wrap;

    .btn.facebook,
    .btn.instagram {
        padding: 12px 75px;
        border: 1px solid #000000;
        box-sizing: border-box;
        border-radius: 3px;
        margin: 10px;
        text-decoration: none;
        max-width: 280px;
        transition: all ease 0.3s;
        &:hover {
            background: #f0dfd3;
            border-color: #f0dfd3;
        }
        .btn.text {
            font-weight: 600;
            font-size: 18px;
            text-align: center;
            text-transform: uppercase;
            color: #000000;
            display: flex;
            align-items: center;
        }
        .btn.text.fb::before {
            font-size: 25px;
            padding-right: 15px;
            content: url(/media/catalog/facebook.png);
        }
        .btn.text.insta::before {
            font-size: 25px;
            padding-right: 15px;
            content: url(/media/catalog/instagram.png);
        }
    }
}

hr {
    border-top: 1px solid white;
    margin-top: 0;
    opacity: 0.3;
    @include max-screen($screen__m) {
        display: none;
    }
}

.footer-container {
    background-color: black;
    .footer-links {
        max-width: $layout__max-width;
        margin-right: auto;
        margin-left: auto;
        padding: 40px 15px 15px;
        .footer-information-stepper {
            display: flex;
            justify-content: space-between;
            padding: 0 40px 40px;
            @include max-screen($screen__m) {
                flex-direction: column;
                padding: 15px;
                border: 0.51px solid #ffffff7d;
                box-sizing: border-box;
            }
            .first-text-footer,
            .second-text-footer,
            .third-text-footer {
                font-weight: 300;
                font-size: 18px;
                line-height: 22px;
                color: #ffffff;
                position: relative;
                padding: 20px 80px;
                @include max-screen($screen__m) {
                    justify-content: center;
                    font-size: 16px;
                }

                &:before {
                    position: absolute;
                    left: 25px;
                    top: calc(50% - 15px);
                    width: 42px;
                    height: 42px;
                }
            }
            .first-text-footer::before {
                content: url(/media/catalog/credit-card.png);
            }

            .second-text-footer::before {
                content: url(/media/catalog/delivery-truck.png);
            }

            .third-text-footer::before {
                content: url(/media/catalog/contact.png);
            }
        }

        .second-list-footer {
            display: flex;
            justify-content: space-around;
            margin: 90px auto 100px 0;
            li {
                margin-bottom: 0;
            }
            @include max-screen($screen__m) {
                flex-direction: column;
                margin: 0 !important;
                padding-top: 50px;
            }
            .heading {
                font-weight: 500;
                font-size: 17px;
                line-height: 22px;
                margin-bottom: 30px;
                color: #f2f2f2;
            }
            .footer-list {
                list-style: none;
                padding-left: 0;
                @include max-screen($screen__m) {
                    padding-left: 0;
                }
                .list-footer {
                    line-height: 230%;
                    text-transform: uppercase;
                    color: #f2f2f2;
                    opacity: 0.6;
                }
            }
            .third-block {
                @include max-screen($screen__m) {
                    padding-left: 0;
                }
                .third-block-images {
                    .footer-block-image {
                        width: 38px;
                        height: 24px;
                    }
                }
            }
        }
        .second-block-footer {
            display: flex;
            justify-content: space-around;
            margin: auto;
            flex-wrap: wrap;
            padding: 0 40px 40px;
            @include max-screen($screen__m) {
                padding: 0px;
                justify-content: start;
            }
            .logo-block {
                width: 36%;
                .logo-footer-img {
                    max-width: 133px;
                    max-height: 133px;
                    margin-bottom: 25px;
                }
                @include max-screen($screen__m) {
                    width: 100%;
                    padding-inline-start: 0 !important;
                }
                .text-image {
                    font-size: 14px;
                    line-height: 230%;
                    color: #f2f2f2;
                    opacity: 0.6;
                    margin-top: 0;
                }
            }
            .block.newsletter {
                margin-bottom: 0;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                padding-top: 15px;
                button.action.subscribe.primary {
                    padding-bottom: 12px;
                    padding-top: 15px;
                    margin-bottom: 5px;
                    margin-right: 3px;
                }
                .form.subscribe {
                    display: flex;
                    align-items: flex-end;
                    width: fit-content;
                    position: relative;
                    .field.newsletter {
                        max-width: 327px;
                    }
                    .field {
                        margin-right: 0;
                        .control {
                            &:before {
                                content: none;
                            }
                            .label {
                                position: relative;
                                font-size: 18px;
                                line-height: 22px;
                                color: #f2f2f2;
                                @include max-screen($screen__m) {
                                    font-size: 16px;
                                }
                            }
                        }
                    }
                    .actions {
                        position: absolute;
                        width: auto;
                        right: 3px;
                        top: 38px;
                    }
                    input#newsletter {
                        max-width: 327px;
                        width: -webkit-fill-available;
                        height: 56px;
                        border: 1px solid #ffffff;
                        box-sizing: border-box;
                        border-radius: 3px;
                        background-color: black;
                        margin-top: 10px;
                        color: white;
                        padding-right: 110px;
                        &::placeholder {
                            text-transform: uppercase;
                            font-size: 12px;
                            font-weight: 500;
                            font-size: 14px;
                            color: #828282;
                        }
                    }
                    .action.subscribe.primary {
                        background: #ffffff;
                        border: 1px solid #000000;
                        box-sizing: border-box;
                        color: black;
                    }
                }
            }
        }
        .copyrights-footer {
            display: flex;
            margin: 0 auto;
            padding: 0 40px;
            justify-content: space-between;
            @media (max-width: 426px) {
                padding: 0;
            }
            .copyrights-text, .branding-satoripop {
                display: flex;
                align-items: center;
                font-size: 12px;
                text-align: center;
                text-transform: uppercase;
                color: #ffffff;
                opacity: 0.4;
                a {
                    margin-left:5px ;
                    margin-top: 2px;
                }
            }
        }
        .footer-divider {
            @media (max-width: 426px) {
                display: block;
                margin-top: 20px;
                margin-bottom: 20px;
            }
        }
    }
}

//Online Purchase Widget
.online_puchase_container {
    width: 100%;
    height: auto;
    min-height: 415px;
    padding: 45px 0 0;
    margin-bottom: 30px;
    background: #fcf6f2;
    .title-favorite-block {
        position: relative;
        margin: 10px 0 50px;
        font-weight: 600;
        font-size: 30px;
        line-height: 110%;
        text-align: center;
        text-transform: uppercase;
        color: #000000;
        &:after {
            position: absolute;
            content: "";
            top: 45px;
            left: 50%;
            width: 30px;
            height: 2px;
            margin-left: -15px;
            background-color: #d3c3b8;
        }
    }
    .description-online-purchase {
        text-align: center;
        text-transform: uppercase;
        margin: auto;
        width: 35%;
        font-size: 15px;
        font-weight: 500;
        line-height: 130%;
        @media (max-width: 426px) {
            width: 70%;
        }
    }
    .wrapper-progressBar {
        max-width: 80%;
        margin: auto;
        .stepper-wrapper {
            margin-top: auto;
            display: flex;
            justify-content: space-between;
            margin-bottom: 20px;
            padding-top: 50px;
            @media (max-width: 426px) {
                flex-direction: column;
            }
            .stepper-item {
                position: relative;
                display: flex;
                flex-direction: column;
                align-items: center;
                flex: 1;
                &:after {
                    position: absolute;
                    content: "";
                    border: 1px dashed #000000;
                    width: 100%;
                    top: 40%;
                    left: 50%;
                    z-index: 3;
                }
                @media (max-width: 768px) {
                    font-size: 12px;
                }
                @media (max-width: 426px) {
                    padding-bottom: 50px;
                    &:after {
                        content: none;
                    }
                }
                .step-counter {
                    background-color: #f1dbcb;
                    position: relative;
                    z-index: 5;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 110px;
                    height: 110px;
                    border-radius: 50%;
                    margin-bottom: 6px;
                    @media (max-width: 426px) {
                        &:before {
                            content: "";
                            height: 20px;
                            border: 1px dashed #000000;
                            position: absolute;
                            bottom: -93px;
                        }
                    }
                    .step-image {
                        transform: translate(0, -50%);
                        position: absolute;
                        top: 50%;
                    }
                }
                .step-counter.last {
                    &:before {
                        content: none;
                    }
                }
                .step-name {
                    text-align: center;
                    font-size: 14px;
                    line-height: 110%;
                    color: #000000;
                }
            }
            .stepper-item.completed::after {
                content: none;
            }
        }
    }
}

//Favorite Products Widget
.wrapped-container {
    flex-wrap: wrap;
    .col-6 {
        @include max-screen($screen__m) {
            width: 100% !important;
        }
        .first-block-favorite {
            width: 100%;
            position: relative;
            .image-description {
                text-align: center;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 100%;
                .image-description-first {
                    font-size: 30px;
                    text-align: center;
                    text-transform: uppercase;
                    color: #ffffff;
                    margin-bottom: 0;
                    font-weight: 600;
                    @media (max-width: 426px) {
                        font-size: 27px;
                    }
                }
                .image-description-second {
                    font-weight: 600;
                    font-size: 25px;
                    text-align: center;
                    text-transform: capitalize;
                    color: #ffffff;
                    /* text-shadow: 0px 4px 4px rgb(0 0 0 / 25%); */
                    margin-top: 0;
                }
            }
            .button-link {
                font-size: 14px;
                line-height: 48px;
                font-weight: 600;
                text-align: center;
                text-transform: uppercase;
                color: #ffffff;
                border: 1px solid #ffffff;
                border-radius: 3px;
                text-decoration: none;
                padding: 10px 15px;
            }

            .image-favorite-block {
                padding: 0 20px 0px 50px;
                @include max-screen($screen__m) {
                    padding: 0;
                }
                .image-favorite {
                    width: 100%;
                    object-fit: cover;
                    @media (max-width: 1025px) and (min-width: 768px) {
                        height: 532px;
                    }
                }
            }
        }
    }
}

.featured-bloc {
    .container {
        padding-left: 0;
        padding-right: 0;
    }
}
.youtube-video {
    display: flex;
    justify-content: center;
    margin-bottom: 35px;
}

//Brands Widget
.am-slider-container {
    .amslider-header-container {
        position: relative;
        margin: 10px 0 50px;
        font-weight: 600;
        font-size: 30px;
        line-height: 110%;
        text-align: center;
        text-transform: uppercase;
        background: none;
        &:after {
            position: absolute;
            content: "";
            top: 45px;
            left: 50%;
            width: 30px;
            height: 2px;
            margin-left: -15px;
            background-color: #d3c3b8;
        }
    }
    .swiper-wrapper {
        .swiper-brand {
            border: 1px solid #f5e7dc;
            box-sizing: border-box;
            border-radius: 3px;
            padding: 0 !important;
        }
    }
    .swiper-pagination {
        .swiper-pagination-bullet {
            height: 8px;
            width: 8px;
        }
        .swiper-pagination-bullet-active {
            background: #dda982;
        }
    }
    .swiper-button-prev,
    .swiper-button-next {
        display: none;
    }
}

//Instagram Feed Widget
.mpinstagramfeed-container {
    .mpinstagramfeed-header {
        h3 {
            position: relative;
            margin: 10px 0 50px;
            font-weight: 600;
            font-size: 30px;
            line-height: 110%;
            text-align: center;
            text-transform: uppercase;
            color: #000000;
            &:after {
                position: absolute;
                content: "";
                top: 45px;
                left: 50%;
                width: 30px;
                height: 2px;
                margin-left: -15px;
                background-color: #d3c3b8;
            }
            &:before {
                padding-right: 15px;
                content: url(/media/catalog/Group3658.png);
            }
        }
        div {
            font-size: 15px;
            text-align: center;
            color: #000000;
            font-weight: 600;
            width: 75%;
            margin: auto;
            padding-bottom: 20px;
        }
    }
    .row.shuffle {
        .mpinstagramfeed-photo {
            .mpinstagramfeed-post-url {
                .mpinstagramfeed-post-caption {
                    width: 100%;
                    height: 100%;
                    max-height: fit-content;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: white;
                    background-color: rgba(0, 0, 0, 0.7);
                }
            }
        }
    }
}

//Product Slider
.mp-product-slider-content {
    .product-item {
        .slider-product-item-info {
            border-radius: 3px;
        }
    }
    .owl-dots {
        margin-top:0 !important ;
        margin-bottom: 15px;
    }
}
.featured-bloc {
    .page-main {
        .wrapped-container {
            .mp-product-slider-block {
                @include max-screen($screen__m) {
                    padding-top: 20px;
                }
                .mp-product-slider-title {
                    display: none;
                }
            }
            .block-content.mp-product-slider-content {
                .price-box {
                    margin: 10px 0 5px;
                }
                .owl-nav {
                    .owl-prev, .owl-next {
                        &:hover {
                            background: none;
                        }
                    }
                }
            }
            .product-image-container {
                .product-image-photo {
                    max-height: 196px;
                }
            }
            .owl-theme {
                .owl-nav[class*="owl-"] {
                    position: absolute;
                    text-align: initial;
                    top: 85%;
                    width: 75px;
                    @include max-screen($screen__m) {
                        top: auto;
                        bottom: 50px;
                        margin-left: auto;
                        margin-right: auto;
                        left: 0;
                        right: 0;
                    }
                }
            }
        }
    }
}

/**************************/

.slick-slider {
    padding: 0 !important;

    .ambanner-image-block {
        display: block;
        width: 100%;

        img {
            object-fit: cover;
            width: 100%;
        }
    }
}

#social-login-popup {
    .social-login-title {
        background-color: #000000;
    }

    .social-login #bnt-social-login-authentication,
    .forgot .primary button,
    .create .primary button,
    .fake-email .primary button {
        display: inline-block;
        padding: 10px 30px;
        border-radius: 3px;
        border: 1px solid #000000;
        font-weight: 500;
        font-size: 15px;
        line-height: 20px;
        text-align: center;
        cursor: pointer;
        transition: all ease 0.3s;

        &:hover {
            background: #000000;
            color: #ffffff;
            text-decoration: none;
        }
    }
}
.youtube-video.container {
    iframe {
        height: 547px ;
        @media(max-width:426px) {
            height: auto;
        }
    }
}

//Slider 
.ambanner-preloader-block {
        position: relative;
            &:after {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                display: block;
                content: '';
            }
}
.ambanner-slider-block {
    & {
        overflow: hidden;
    }

    &.slick-slider {
        padding: 0;
    }

    &.slick-slider.slick-dotted {
        margin-bottom: 20px !important; 
    }

    &.-ambanner-preloader:not(.-ambanner-loaded) {
        position: relative;
        height: 30vh;
    }

    &:not(.-ambanner-loaded) .ambanner-item-block:not(:first-of-type) {
        display: none;
    }
}
.ambanner-slider-container {
  .ambanner-slider-wrap {
    .ambanner-slider-block.-ambanner-arrows.slick-initialized.slick-slider.-ambanner-hover {
            .ambanner-arrow-button.-prev, .ambanner-arrow-button.-next {
                background: rgba(0, 0, 0, 0.3);
                z-index: 1;
            }
            .ambanner-arrow-button.-next {
                &:before {
                    content: url(/media/catalog/right.svg);
                    vertical-align: text-top;
                }
            }
            .ambanner-arrow-button.-prev {
                &:before {
                    content: url(/media/catalog/left.svg);
                    vertical-align: text-top;
                }
            }   
    }
    .ambanner-slider-block.-ambanner-arrows.slick-initialized.slick-slider {
        .ambanner-arrow-button.-next {
            right: 25px; 
        }
        .ambanner-arrow-button.-prev {
            left: 25px;
        }   
            .ambanner-arrow-button.-prev, .ambanner-arrow-button.-next {
                top: 50%;
                border-radius: 50%;
                font-size: 0;
                background: none;
                border: none;
                position: absolute;
                min-width: 56px;
        }
    }
  }  
}

