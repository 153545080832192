@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600&display=swap');

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    h1 {
        @include lib-css(font-size, $h1__font-size-desktop);
        @include lib-css(margin-bottom, $h1__margin-bottom__desktop);
    }
}

.items {
    @include lib-list-reset-styles();
}
