.account.wishlist-index-index {
    .actions-toolbar {
        margin-bottom: 30px;
        .primary {
            @media(min-width:767px) {
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }
}