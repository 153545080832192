.mp-product-slider-content,
.product-items {
    .product-item {
        text-align: center;
        .slider-product-item-info,
        .product-item-info {
            position: relative;
            display: block;
            background: #fff;
            border: 1px solid #fff;
            padding: 0 15px 50px;
            overflow: hidden;
            transition: all ease 0.3s;
            border-radius: 3px;

            @include max-screen($screen__m) {
                border: 1px solid #000000;
                box-shadow: 0 6px 12px #00000021;
                div.actions-primary {
                    button.action.primary {
                        transform: translateY(0);
                    }
                }
            }
            @include max-screen($screen__s) {
                max-width: 320px;
                margin: auto;
            }
            .brand-name {
                display: block;
                font-weight: 500;
                min-height: 20px;
            }
            .description-text {
                height: 60px;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
            }

            .product-item-name {
                min-height: 75px;
                a {
                    display: block;
                    text-transform: uppercase;
                    font-weight: 600 !important;
                    font-size: 18px !important;
                    margin-bottom: 20px;
                    min-height: 75px;
                    &:hover {
                        text-decoration: none;
                    }
                }
            }

            .price-box {
                font-weight: 600;
                font-size: 18px;
                .price-label {
                    display: none;
                }
                .old-price-2-widget-product-grid {
                    font-weight: 400;
                    font-size: 16px;
                }
            }

            .secondary-addto-links.actions-secondary,
            .actions-secondary {
                position: absolute;
                top: 10px;
                right: 28px;
                width: 24px;
                z-index: 20;
                a {
                    &::before {
                        font-size: 50px !important;
                        opacity: 0.5;
                        transition: all ease 0.3s;
                    }
                    &:hover {
                        &::before {
                            color: #eed4c0 !important;
                            opacity: 1;
                        }
                    }
                }
            }

            div.actions-primary {
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                button.action.primary {
                    width: 100%;
                    border-radius: 3px;
                    background: #000;
                    color: white;
                    border: none;
                    font-weight: 400;
                    text-transform: uppercase;
                    padding: 15px 0;
                    transform: translateY(100%);
                    transition: all ease 0.3s;
                    &:hover {
                        border: none;
                    }
                    @include max-screen($screen__m) {
                        transform: translateY(0);
                    }
                }
            }

            &:hover {
                border: 1px solid #000000;
                box-shadow: 0 6px 12px #00000021;
                div.actions-primary {
                    button.action.primary {
                        transform: translateY(0);
                    }
                }
            }


        }
        .slider-product-item-details {
            padding: 0 15px;
        }
    }

    .owl-nav {
        font-size: 80px;

        button {
            span {
                color: #d6d6d6;

                &:hover {
                    color: #869791;
                }
            }
        }

        .owl-prev {
            position: absolute;
            top: 40%;
            left: 0;
            z-index: 999;
            text-shadow: none;
            background: none;
            border: none;
        }

        .owl-next {
            position: absolute;
            top: 40%;
            right: 0;
            z-index: 999;
            text-shadow: none;
            background: none;
            border: none;
        }
    }

    .product-items {
        .product-slider {
            .slider-product-item-details {
                .slider-product-item-actions {
                    /* margin: 15px 0 10px; */
                    @include max-screen($screen__m) {
                        display: block;
                    }
                }
            }
        }
    }
}

.owl-item {
    li.product-slider {
        width: 100% !important;
    }
}

.mp-product-slider-block {
    margin: 0 !important;

    .owl-carousel {
        .owl-nav {
            button.owl-prev {
                font-size: 20px;
            }

            button.owl-next {
                font-size: 20px;
            }
        }
    }
}

.mp-ps-info {
    font-size: 1.8rem;
    position: relative;
    z-index: 1;
    text-align: center;
    display: block;
    color: #bbbbbb;
    /*margin: -15px 0 10px 0;*/
}

.slider-product-item-actions {
    margin: 0;
}
