.checkout-cart-index {
    .page-title-wrapper {
        width: 100%;
        height: 127px;
        background: #F3E2D6;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 25px;

        .page-title {
            font-weight: 500;
            font-size: 25px;
            text-transform: uppercase;
            color: #000000;
            margin-bottom: 0;
        }
    }

    .cart.table-wrapper {
        tbody.item {
            .product-item-details {
                padding-top: 15px;

                .product-item-name {
                    font-weight: 600;
                    font-size: 15px;
                    line-height: 18px;
                    text-transform: uppercase;
                    margin-top: 0;
                }
            }

            &:not(:first-of-type) {
                &:before {
                    content: '';
                    display: block;
                    height: 15px;
                }
            }

            &>tr {
                border: 1px solid rgba(0, 0, 0, .1);

                .col {
                    .field.qty {
                        label {
                            @media (min-width:768px) {
                                display: flex;
                                flex-direction: column;
                            }

                            input[type="number"] {
                                width: 60px;
                                margin-top: 6px;
                                text-align: center;
                            }
                        }
                    }

                    text-align: left;

                    &.actions {
                        border: 1px solid rgba(0, 0, 0, .1);
                        padding: 0;
                    }

                    &.actions,
                    &.price {
                        height: 160px;

                        @media(max-width:767px) {
                            .price-qty {
                                height: fit-content;
                            }
                        }

                        .field.price,
                        .field.qty {
                            .cart-price {
                                font-weight: 600;
                                font-size: 14px;
                                line-height: 25px;
                            }

                            &>div,
                            .label {
                                font-size: 12px;
                                line-height: 15px;
                                font-weight: normal;
                                text-transform: uppercase;
                            }
                        }



                        &>div {
                            display: flex;
                            flex-direction: column;
                            justify-content: space-around;
                            align-content: flex-start;
                            height: 100%;
                            min-width: 60px;

                            &>a {
                                flex-grow: 1;
                                margin: 0;
                                vertical-align: middle;
                                text-align: center;
                                border-top: 1px solid rgba(0, 0, 0, .1);

                                &:first-child {
                                    border-top: none;
                                }
                            }
                        }
                    }

                    &.actions {
                        .action-towishlist:hover {
                            color: #494949;
                        }

                        .field.actions {

                            a,
                            div {
                                display: table;

                                &>*:not(script) {
                                    display: table-cell;
                                    vertical-align: middle;
                                }
                            }
                        .action-towishlist {
                            border: none;
                            background: none;
                            &:before {
                                font-size: 50px;
                                opacity: 0.5;
                                transition: all ease 0.3s;
                                display: table-cell;
                                vertical-align: middle;
                            }
                            &:hover {
                                &::before { 
                                    color: #eed4c0 !important;
                                    opacity: 1;
                                    }
                                }
                            }  
                        }
                    }
                }
            }
        }
    }



    .cart-summary-main {
        display: flex;
        flex-direction: column-reverse;

        .cart-summary {
            .action.multicheckout {
                &:hover {
                    color: #494949;
                }
            }

            .block > .title {
                border: 0;
                padding: 10px 30px 15px 5px;
            }

            &>.title {
                font-weight: 700;
                text-transform: uppercase;
            }

            .cart-totals {
                border-top: 1px solid rgba(0, 0, 0, .1);
            }

            #discount-giftcard-form {
                .actions-toolbar {
                    justify-content: flex-end;
                }

                .action.secondary {
                    top: -40px;
                    position: relative;
                    left: 5px;
                    line-height: 1.42857;
                    padding: 0;
                    color: #494949;
                    text-decoration: none;
                    background: none;
                    border: 0;
                    display: inline;
                    border-radius: 0;
                    font-size: inherit;
                    font-weight: 400;

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }

            .actions-toolbar {
                display: flex;
                justify-content: center;

                &>.primary {
                    button {

                        color: white;
                        background-color: black;

                        &:hover {
                            border: 1px solid;
                            color: black;
                            background-color: white;
                        }

                    }
                }
            }
        }
    }

    .cart-container {
        .checkout-methods-items {
            .action.primary {
                height: 52px;
            }
        }

        .form-cart {
            .cart.main.actions {
                .action.primary {
                    width: fit-content;
                    height: fit-content;
                    min-height: 35px;

                    @media(max-width:767px) {
                        width: 100%;
                        margin: 5px 0;
                    }
                }
            }

            @media(min-width:768px) {
                width: 66%;
            }
        }
    }
}
.modal-popup.confirm {
    .modal-inner-wrap {
        width: 305px;
    }
}

.additional-infos {
    display: flex;
    padding: 21px 0;
    border-top: 1px solid #c1c1c1;
    border-bottom: 1px solid #c1c1c1;
    margin-bottom: 25px;
    justify-content: space-between;

    .info-block {
        display: flex;

        &>span {
            text-align: center;
        }
    }
}

@media only screen and (min-width: 768px) {
    .cart-summary-main {
        float: right;
        position: relative;
        width: 31%;
    }
}

.checkout-cart-index .cart-summary-main .cart-summary,
.checkout-index-index .opc-block-summary {
    float: none;
    width: 100%;
    border: 1px solid;
    border-radius: 3px;
    /*box-shadow: 0px 1px 2px rgba(0, 0, 0, 25%);*/
    background: white;
    top: 0 !important;


}