a.action.primary,
button {
    @include lib-css(border-radius, $button__border-radius);
}
button {
    &:active {
        @include lib-css(box-shadow, $button__shadow);
    }
}
a.action.primary {
    @include lib-link-as-button();
}
.action.primary, .action-dismiss {
    @include lib-button-primary();
    width: 100%;
    height: 45px;
    border-radius: 3px;
    text-transform: uppercase;
    transition: all ease 0.3s;
}
div.promo-buttons {
    display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: space-between;
    margin: 20px 0 50px;
    /*padding: 0 20px;*/
    @include max-screen($screen__m) {
        flex-wrap: wrap;
        margin: 15px 0;
    }
    a {
        position: relative;
        display: block;
        width: calc(50% - 15px);
        padding: 20px 30px 20px 100px;
        margin-bottom: 15px;
        border: 1px solid #BDBDBD;
        color: #000;
        font-style: normal;
        font-weight: 600;
        font-size: 25px;
        line-height: 110%;
        text-decoration: none;
        text-transform: uppercase;
        transition: all ease 0.3s;
        border-radius: 3px;
        @include max-screen($screen__m) {
            width: 100%;
            padding: 20px 30px 20px 90px;
            &::before {
                left: 14px;
            }
        }
        &::before {
            content: '';   
            position: absolute;
            width: 52px;
            height: 52px;
            left: 24px;
            top: calc(50% - 26px);
            background-size: contain;
            background-repeat: no-repeat;
        }
        &::after {
            content: '';
            position: absolute;
            width: 14px;
            height: 20px;
            right: 8px;
            top: calc(50% - 10px);
            background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMjUiIHZpZXdCb3g9IjAgMCAxNCAyNSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTAuNjI2OTUzIDI0LjQ1OUwxMy4yIDEzLjMzNEMxMy4yNTMgMTMuMjg3MSAxMy4yOTU1IDEzLjIyOTQgMTMuMzI0NSAxMy4xNjQ5QzEzLjM1MzYgMTMuMTAwMyAxMy4zNjg3IDEzLjAzMDMgMTMuMzY4NyAxMi45NTk1QzEzLjM2ODcgMTIuODg4NyAxMy4zNTM2IDEyLjgxODcgMTMuMzI0NSAxMi43NTQxQzEzLjI5NTUgMTIuNjg5NSAxMy4yNTMgMTIuNjMxOSAxMy4yIDEyLjU4NUwwLjYyNjk1MiAxLjQ1ODk4IiBzdHJva2U9ImJsYWNrIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg==");
            background-size: contain;
            background-repeat: no-repeat;
        
        }
        &:hover {
            color: #494949;
            border: 1px solid #f3decf;
            background: #f3decf;
        }
        &.gift-1 {
            &::before {
                background-image: url('../images/icons/gift-1.svg');
            }
        }
        &.gift-2 {
            &::before {
                top: 9px;
                background-image: url('../images/icons/gift-2.svg');
            }
        }
    }
}
a.btn-outline,
button.btn-outline, a.secondary, button.secondary, .action-accept {
    display: inline-block;
    padding: 10px 25px;
    border-radius: 3px;
    border: 1px solid #000000;
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    text-align: center;
    cursor: pointer;
    transition: all ease 0.3s;
    background: white;
    &:hover {
        background: #000000;
        color: #ffffff;
        text-decoration: none;
    }
}