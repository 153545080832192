.block.block-minicart {
    box-shadow: 0px 100px 80px rgba(0, 0, 0, 0.07), 0px 41.7776px 33.4221px rgba(0, 0, 0, 0.05),
        0px 22.3363px 17.869px rgba(0, 0, 0, 0.04), 0px 12.5216px 10.0172px rgba(0, 0, 0, 0.04),
        0px 6.6501px 5.32008px rgba(0, 0, 0, 0.03), 0px 2.76726px 2.21381px rgba(0, 0, 0, 0.02);
    border-radius: 3px;
    border: 1px solid #f2f2f2;
    padding: 11px 20px;
    max-width: 333px;

    .block-content {
        .items-total {
            float: none;

            .count,
            .item-text {
                font-size: 16px;
                font-weight: 600;
            }
        }

        .subtotal {
            display: flex;
            justify-content: space-between;
            align-items: baseline;
            text-align: start;

            .label {
                color: black;
                font-weight: 500;
            }
        }

        .actions {
            .secondary {
                width: 100%;
            }
            .primary {
                margin: 0;

                .action.primary.checkout.social-login-btn {
                    font-size: 16px;
                    text-transform: uppercase;
                }
                #top-cart-btn-checkout {
                    height: 62px;
                }
            }
        }

        .minicart-widgets {
            margin-top: 0;
        }

        .actions {
            .secondary {
                .action.viewcart {
                    width: 100%;
                    padding: 20px;
                    font-size: 16px;
                    line-height: 20px;
                    text-align: center;
                    text-transform: uppercase;
                }
            }
        }

        .product-item {
            border-top: none;

            .product {
                position: relative;

                .product-item-photo {
                    position: absolute;
                    top: 25%;
                    width: 25%;

                    .product-image-container {
                        display: initial;
                    }
                }

                .product-item-details {
                    margin-top: 37px;

                    .product-manufacturer-minicart {
                        font-size: 12px;
                        line-height: 110%;
                        text-transform: uppercase;
                        color: #000000;
                        margin-bottom: 8px;
                    }

                    .product-item-name {
                        a {
                            font-weight: 600;
                            font-size: 16px;
                            line-height: 20px;
                            text-transform: uppercase;
                            color: #000000;
                        }
                    }

                    .product-description-minicart {
                        font-size: 14px;
                        line-height: 120%;
                        text-transform: capitalize;
                        color: #000000;
                        margin-bottom: 17px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                    }

                    .product-item-pricing {
                        .price-container {
                            .price-wrapper {
                                .price {
                                    font-weight: bold;
                                    font-size: 16px;
                                    line-height: 110%;
                                    color: #000000;
                                }
                            }
                        }
                    }
                }

                .product.actions {
                    position: absolute;
                    right: 0;
                    top: 30px;
                }
            }
        }
    }
}
.modal-inner-wrap {
    .modal-footer {
        text-align: center;
        .action-secondary.action-dismiss {
            width: auto;
        }
        .action-primary.action-accept {
            padding: 11px 15px;
        }
    }
}

