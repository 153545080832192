@keyframes slide-right {
    0% {
        transform: translateX(-500%);
    }

    100% {
        transform: translateX(0);
    }
}

@keyframes slide-left {
    0% {
        transform: translateX(500%);
    }

    100% {
        transform: translateX(0);
    }
}

.ammenu-menu-wrapper {
    .ammenu-item {
        margin: 0;
    }

    .ammenu-arrow {
        position: absolute;
        right: 10px;
        z-index: 2;
        margin: 0;
        width: 20px;
        height: 20px;
        opacity: 0;
        cursor: pointer;
        transition: all 0.3s ease;
        offset-path: none;

        &:before {
            position: absolute;
            display: block;
            width: 12px;
            height: 2px;
            background: #000;
            content: "";
            transition: none;
            transform-origin: 100% 50%;
        }

        &:after {
            position: absolute;
            display: block;
            width: 12px;
            height: 2px;
            background: #000;
            content: "";
            transition: none;
            transform-origin: 100% 50%;
        }
    }

    .ammenu-nav-sections {
        z-index: 9;
        background: #fff;
    }

    .ammenu-main-container {
        box-sizing: border-box;
        width: 100%;
    }

    .ammenu-link {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        border: none;
        border-radius: 0;
        background: none;
        font-weight: inherit;
        margin: 0;
        padding: 0;
        width: 100%;
        outline: none;
        box-shadow: none;
        color: inherit;
        overflow: hidden;
        font-size: 14px;

        .reset-button {
            border: none;
            border-radius: 0;
            background: none;
            box-shadow: none;
            color: inherit;
            font-weight: inherit;
        }

        &:focus {
            border: none;
            border-radius: 0;
            background: none;
            box-shadow: none;
            color: inherit;
            font-weight: inherit;
        }

        &:after {
            content: "";
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 3px;
            background: #bb0339;
            transform: translateX(-101%);
            transition: all ease 0.3s;
        }

        &:hover {
            border: none;
            border-radius: 0;
            background: none;
            box-shadow: none;
            color: inherit;
            font-weight: inherit;
            text-decoration: none;
            &:after {
                transform: translateX(0);
            }
        }
    }

    .ammenu-label {
        display: inline-block;
        padding: 2px 3px;
        min-width: 42px;
        border-radius: 2px;
        background: #fff;
        color: #000;
        text-align: center;
        font-size: 0.9rem;
        line-height: normal;
    }

    .ammenu-icon-block {
        position: relative;
        display: inline-block;
        margin: 0 8px 0 0;
        min-width: 18px;
        width: 18px;
        height: 18px;
        background-position: center center;
        background-size: contain;
        background-repeat: no-repeat;
        -webkit-mask-size: cover;
        mask-size: cover;
    }

    .ammenu-menu-overlay {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 9;
        width: 100vw;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.2);
        cursor: pointer;
    }

    table {
        img {
            max-width: inherit;
        }
    }

    [data-appearance="carousel"] {
        min-width: 200px;
        max-width: 100%;
        width: 100%;
    }
}
.ammenu-tabs-list {
    display: flex;
    margin: 0;
    padding: 0;

    .ammenu-item {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-grow: 1;
        cursor: pointer;
    }

    .ammenu-button {
        border: none;
        border-radius: 0;
        background: none;
        box-shadow: none;
        color: inherit;
        font-weight: inherit;
        margin: 0;
        padding: 0;
        transition: 0.1s all ease-in;
        width: 100%;
        min-height: 50px;
        text-transform: uppercase;

        .reset-button {
            border: none;
            border-radius: 0;
            background: none;
            box-shadow: none;
            color: inherit;
            font-weight: inherit;
        }

        &:focus {
            border: none;
            border-radius: 0;
            background: none;
            box-shadow: none;
            color: inherit;
            font-weight: inherit;
        }

        &:hover {
            border: none;
            border-radius: 0;
            background: none;
            box-shadow: none;
            color: inherit;
            font-weight: inherit;
            color: #bb0339;
        }
    }

    .ammenu-button.-active {
        font-weight: 600;
    }
}

.ammenu-button {
    outline: none;
}

.ammenu-toggle {
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: 0;
    background: none;
    box-shadow: none;
    color: inherit;
    font-weight: inherit;
    margin: 0;
    padding: 0;
    z-index: 1;
    align-self: stretch;
    width: 25px;
    outline: none;
    cursor: pointer;
    transition: 0.1s;

    .reset-button {
        border: none;
        border-radius: 0;
        background: none;
        box-shadow: none;
        color: inherit;
        font-weight: inherit;
    }

    &:focus {
        border: none;
        border-radius: 0;
        background: none;
        box-shadow: none;
        color: inherit;
        font-weight: inherit;
    }

    &:hover {
        border: none;
        border-radius: 0;
        background: none;
        box-shadow: none;
        color: inherit;
        font-weight: inherit;
    }

    .ammenu-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        display: inline-block;
        box-sizing: border-box;
        margin: 0 0 -5px;
        width: 14px;
        height: 14px;
        border-style: solid;
        border-width: 2px 2px 0 0;
        border-radius: 2px;
        cursor: pointer;
        transform: rotate(315deg);
    }
}

.ammenu-button.-hamburger {
    display: flex;
    align-items: center;
    position: relative;
    display: inline-flex;
    margin: 0;
    padding: 0;
    width: 25px;
    height: 17px;
    border: none;
    background: transparent;
    cursor: pointer;

    &:hover {
        border: none;
        background: transparent;
    }

    &:focus {
        border: none;
        background: transparent;
    }

    &:not(.-hamburger) {
        display: none;
    }

    &:before {
        position: absolute;
        display: block;
        margin: 0;
        width: 100%;
        border-color: inherit;
        border-bottom: 1px solid;
        border-radius: 1px;
        content: "";
        transition: all 0.3s ease;
        top: 0;
    }

    &:after {
        position: absolute;
        display: block;
        margin: 0;
        width: 100%;
        border-color: inherit;
        border-bottom: 1px solid;
        border-radius: 1px;
        content: "";
        transition: all 0.3s ease;
        bottom: 0;
    }

    .ammenu-line {
        position: absolute;
        display: block;
        margin: 0;
        width: 100%;
        border-color: inherit;
        border-bottom: 1px solid;
        border-radius: 1px;
        content: "";
        transition: all 0.3s ease;
    }
}

.ammenu-button.-close {
    border: none;
    border-radius: 0;
    background: none;
    box-shadow: none;
    color: inherit;
    font-weight: inherit;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    color: #6d6d6d;
    cursor: pointer;

    .reset-button {
        border: none;
        border-radius: 0;
        background: none;
        box-shadow: none;
        color: inherit;
        font-weight: inherit;
    }

    &:focus {
        border: none;
        border-radius: 0;
        background: none;
        box-shadow: none;
        color: inherit;
        font-weight: inherit;
    }

    &:hover {
        border: none;
        border-radius: 0;
        background: none;
        box-shadow: none;
        color: inherit;
        font-weight: inherit;
        color: #000;
    }

    &:before {
        display: flex;
        justify-content: center;
        align-items: center;
        background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTUiIGhlaWdodD0iMTUiIHZpZXdCb3g9IjAgMCAxNSAxNSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTE1IDEuMTYzMTRMMTMuODM2NCAwTDcuNSA2LjMzNzI5TDEuMTYzNTcgMEwwIDEuMTYyNzFMNi4zMzcyOSA3LjVMMC4wMDA4NTcxNDMgMTMuODM3M0wxLjE2MzU3IDE1TDcuNSA4LjY2MzE0TDEzLjgzNjQgMTQuOTk5NkwxNC45OTgzIDEzLjgzNjlMOC42NjI3MSA3LjVMMTUgMS4xNjMxNFoiIGZpbGw9IiMzNDM0MzQiLz48L3N2Zz4=);
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
        content: "";
        width: 15px;
        height: 15px;
    }
}

.ammenu-nav-sections.-mobile {
    .ammenu-settings-list {
        padding: 20px 0;
    }

    .ammenu-items.-account-links {
        margin: 15px 0;

        .ammenu-counter {
            padding: 0 0 0 5px;
            text-transform: capitalize;
            font-size: 0.8em;
            opacity: 0.5;

            &:before {
                content: "(";
            }

            &:after {
                content: ")";
            }
        }
    }

    .ammenu-settings-block {
        padding: 15px 0;
        border-bottom: 1px solid #c4c4c4;

        .ammenu-toggle {
            transform: rotate(180deg);
        }

        .ammenu-toggle.-active {
            transform: rotate(0);
        }

        .ammenu-text {
            padding: 5px 0;
            max-width: 70%;
        }

        .ammenu-title {
            margin: 0;
            padding: 0 20px 0;
            text-transform: uppercase;
            font-weight: 600;
            font-size: 1.8rem;
        }

        .ammenu-items.-dropdown {
            padding: 10px 0 0 10px;

            .ammenu-link {
                justify-content: space-between;
                height: 30px;
            }
        }

        .ammenu-code {
            margin: 0 0 0 10px;
            text-transform: uppercase;
        }
    }

    transition: 0.3s all ease-in;
    position: fixed;
    top: 0;
    left: -100%;
    z-index: 99999;
    overflow: auto;
    margin-bottom: 25px;
    padding: 0 0 40px;
    max-width: 300px;
    width: 100%;
    height: 100vh;

    /*
    &:not(.-has-icons) {
        .ammenu-items.-root {
            > .ammenu-item {
                > .ammenu-link {
                    &:before {
                        box-sizing: border-box;
                        margin: 0 10px 0 0;
                        min-width: 10px;
                        width: 10px;
                        height: 10px;
                        border: 2px solid;
                        border-radius: 12px;
                        content: "";
                    }
                }
            }
        }
    }
    */

    .ammenu-items {
        flex-basis: 100%;
        margin: 0;
        padding: 0;
    }

    .ammenu-items.-root {
        > .ammenu-item {
            margin: 0;
            border-bottom: 1px solid #c4c4c4;

            > .ammenu-link {
                display: flex;
                align-items: center;
                flex-basis: 100%;
                box-sizing: border-box;
                min-height: 50px;
                text-transform: uppercase;
                /*font-weight: 600;
                font-size: 1.6rem;*/
            }

            .ammenu-item {
                margin: 0;
                padding: 0 0 0 25px;

                .ammenu-toggle {
                    .ammenu-icon {
                        width: 11px;
                        height: 11px;
                    }
                }
            }
        }
    }

    .ammenu-label {
        transform: translate(8%, -50%);
    }

    .ammenu-toggle {
        margin: 0 0 0 auto;
        box-shadow: none;
    }

    .ammenu-item {
        position: relative;
    }

    .ammenu-link {
        flex-basis: 100%;
        flex-grow: 1;
        box-sizing: border-box;
        padding: 0 20px;
        text-align: left;
        min-height: 50px;
    }

    .ammenu-text {
        word-wrap: break-word;
        word-break: break-word;
        overflow: hidden;
        box-sizing: border-box;
        padding: 10px 0;
        max-height: 100%;
        vertical-align: middle;
        text-align: left;
        text-overflow: ellipsis;
    }

    &:not(.-left-menu) {
        .ammenu-menu-header {
            padding: 16px 20px;
            border-bottom: 1px solid #c4c4c4;
            display: none;
        }

        .ammenu-items.-root {
            padding: 0 0 20px;
        }
    }
}

.ammenu-menu-header {
    display: flex;
    align-items: center;
    position: relative;
    margin: 0;
    font-weight: 700;
    font-size: 1.6rem;

    .ammenu-message.-welcome {
        margin: 0 5px 0 0;

        &:after {
            content: ",";
        }
    }

    .ammenu-name {
        border-bottom: 1px dashed;

        &:after {
            content: "!";
        }
    }
}

.ammenu-categories-container {
    display: flex;
    flex-wrap: wrap;

    .ammenu-column.-col-10 {
        flex-basis: calc(100% / 10);
    }

    .ammenu-column.-col-9 {
        flex-basis: calc(100% / 9);
    }

    .ammenu-column.-col-8 {
        flex-basis: calc(100% / 8);
    }

    .ammenu-column.-col-7 {
        flex-basis: calc(100% / 7);
    }

    .ammenu-column.-col-6 {
        flex-basis: calc(100% / 6);
    }

    .ammenu-column.-col-5 {
        flex-basis: calc(100% / 5);
        max-width: 20%;
    }

    .ammenu-column.-col-4 {
        flex-basis: calc(100% / 4);
        max-width: 25%;
    }

    .ammenu-column.-col-3 {
        flex-basis: calc(100% / 3);
        max-width: 33%;
    }

    .ammenu-column.-col-2 {
        flex-basis: calc(100% / 2);
        max-width: 50%;
    }

    .ammenu-column.-col-1 {
        flex-basis: calc(100% / 1);
    }

    .ammenu-list {
        display: inline-flex;
        align-items: flex-start;
        flex-direction: column;
        padding: 0;

        .ammenu-list {
            padding: 0 0 0 15px;
        }
    }

    .ammenu-column {
        flex-basis: 100%;
        box-sizing: border-box;
        padding: 0;

        &:not(:last-child) {
            padding-right: 15px;
        }

        > .ammenu-list {
            padding-bottom: 20px;
        }
    }
}

.ammenu-nav-sections.-topmenu.-hamburger {
    padding: 0 20px;

    .ammenu-main-container {
        padding: 0;
        /*max-width: 1240px;*/
    }
}

.ammenu-nav-sections.-left-menu {
    box-shadow: 2px 0 10px rgba(0, 0, 0, 0.15);

    .ammenu-button.-close {
        position: absolute;
        top: 25px;
        right: -30px;
        pointer-events: none;
    }

    .ammenu-menu-title {
        display: block;
        font-weight: 600;
    }

    .ammenu-menu-header {
        margin: 0 0 20px;
        color: #fff;
        font-size: 1.4rem;
    }

    .switcher {
        box-sizing: border-box;
        padding: 30px 0 0;

        .switcher-options {
            padding: 0 25px;
        }

        .switcher-label {
            padding: 0 25px 10px;
            border: none;
            text-transform: uppercase;
            font-weight: 600;
            line-height: 25px;
        }

        .switcher-trigger {
            padding: 0;
            font-size: 1.6rem;

            strong {
                position: relative;
                display: block;
                width: 100%;

                &:after {
                    position: absolute;
                    top: 2px;
                    right: 0;
                    display: block;
                    width: 10px;
                    height: 10px;
                    border-width: 0 2px 2px 0;
                    border-style: solid;
                    content: "";
                    transform: rotate(45deg);
                }
            }
        }

        .switcher-trigger.active {
            strong {
                &:after {
                    top: 8px;
                    transform: rotate(225deg);
                }
            }
        }
    }

    .ammenu-categories-container {
        .ammenu-column {
            > .ammenu-link {
                text-transform: uppercase;
            }
        }

        .ammenu-text {
            transition: 0.1s all ease-in;
            padding: 5px;
            border: 1px solid transparent;
            border-radius: 2px;
            /*font-weight: 600;
            font-size: 1.6rem;*/

            &:hover {
                border-color: inherit;
            }
        }
    }

    .ammenu-items.-root {
        > .ammenu-item {
            > .ammenu-link {
                flex-basis: 100%;
                text-transform: uppercase;
                /*font-weight: 600;*/
            }
        }
    }

    .pagebuilder-column {
        min-width: min-content;
        min-width: 120px;
    }

    [data-content-type="html"] {
        min-width: 120px;
    }

    [data-content-type="video"] {
        min-width: 120px;
    }

    &:not(.-mobile) {
        position: fixed;
        top: 0;
        left: -100%;
        z-index: 99999;
        overflow: visible;
        margin-bottom: 25px;
        max-width: 360px;
        width: 90%;
        height: 100vh;
        transition: all 0.3s ease;

        .ammenu-menu-header {
            padding: 22px 30px;
        }

        .ammenu-menu-title {
            padding: 0 30px;
        }

        .ammenu-label {
            transform: translate(8%, -50%);
        }

        .ammenu-items {
            margin: 0;
            padding: 0 30px;
        }

        .ammenu-items.-root {
            > .ammenu-item {
                position: relative;
                padding: 10px 25px 10px 0;
                text-transform: capitalize;
                font-size: 1.6rem;

                .ammenu-link {
                    .ammenu-toggle {
                        position: absolute;
                    }
                }
            }
        }

        .ammenu-item {
            > .ammenu-text {
                padding: 0 10px 0 0;
                text-align: left;
                white-space: nowrap;
                font-size: 1.6rem;
            }
        }

        .ammenu-items-list {
            > .ammenu-item {
                .ammenu-link {
                    .ammenu-text {
                        padding: 5px;
                        border: 1px solid transparent;
                        border-radius: 2px;
                        text-transform: uppercase;
                        /*font-weight: 600;*/
                        font-size: 13px;
                        &:hover {
                            border-color: inherit;
                        }
                    }
                }
            }
        }

        .ammenu-text {
            > .ammenu-link {
                .ammenu-text {
                    padding: 5px;
                    border: 1px solid transparent;
                    border-radius: 2px;
                    text-transform: uppercase;
                    /*font-weight: 600;*/
                    font-size: 14px;

                    &:hover {
                        border-color: inherit;
                    }
                }
            }
        }

        .ammenu-submenu-block.-root {
            overflow-y: auto;
            scrollbar-color: #adadad rgba(173, 173, 173, 0.2);
            scrollbar-width: thin;
            position: absolute;
            top: 0;
            left: calc(100% + 30px);
            margin: 0;
            padding: 15px;
            max-width: 64vw;
            max-height: 70vh;
            box-shadow: 3px 2px 8px rgba(0, 0, 0, 0.15);

            &::-webkit-scrollbar {
                background: #f0f0f0;
                width: 6px;
                height: 12px;
            }

            &::-webkit-scrollbar-thumb {
                border-radius: 6px;
                background: #adadad;
                cursor: pointer;
                width: 6px;
                height: 6px;
            }

            &::-webkit-scrollbar-track {
                box-shadow: inset 0 0 5px #fff;
                width: 6px;
                height: 6px;
            }
        }

        .ammenu-toggle {
            top: 0;
            right: 0;
            bottom: 0;
            box-shadow: none;
            transform: rotate(90deg);
        }

        .ammenu-toggle.-active {
            transform: rotate(-90deg);
        }
    }

    &:not(.-mobile).-opened {
        left: 0;
    }
}

.ammenu-nav-sections.-left-menu.-mobile {
    overflow-x: hidden;

    .ammenu-menu-title {
        margin: 20px 0;
        padding: 0 20px;
        font-size: 1.4rem;
    }

    .ammenu-menu-header {
        padding: 22px 20px;
    }

    .ammenu-item {
        display: flex;
        flex-basis: 100%;
        flex-wrap: wrap;
    }
}

.ammenu-nav-sections.-mobile.-opened {
    left: 0;
}

.ie11 {
    .ammenu-nav-sections.-mobile {
        .ammenu-text {
            word-break: break-all;
        }
    }
}

.ammenu-nav-sections.-topmenu {
    transition: 0.1s all ease-in;
    margin: 0 0 15px 0;

    .ammenu-main-container {
        transition: 0.1s all ease-in;
        box-sizing: border-box;
        margin: auto;
        /*padding: 0 20px;*/
    }

    .ammenu-items {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        margin: 0;
        padding: 0;
        list-style: none;
        @media(max-width:1025px) {
            justify-content: center;
        }
    }

    .ammenu-items.-root {
        > .ammenu-item {
            display: inline-block;
            box-sizing: border-box;
            font-weight: 700;
            font-size: 1.6rem;
            cursor: pointer;
            transition: 0.5s;
            margin: 0 8px;
            &:first-child {
                margin-left: 0 !important;
            }
            &:last-child {
                margin-right: 0;
            }
            &:hover {
                background: #fff;

                > .ammenu-link {
                    text-decoration: none;
                }
            }
            &:hover + .ammenu-submenu-block {
                display: flex;
            }

            > .ammenu-link {
                position: relative;
                padding: 15px 0px;
                font-size: 13px;
                line-height: 17px;
                text-transform: uppercase;
            }
        }
    }

    .ammenu-label {
        position: absolute;
        top: 0;
        right: 0;
    }

    .ammenu-categories-container {
        .ammenu-item {
            position: relative;
            display: inline-flex;
            align-items: center;

            &:not(:last-child) {
                padding: 0 0 10px;
            }

            .ammenu-link {
                position: relative;
                font-size: 13px;
                font-weight: normal;
                text-transform: lowercase;
                transition: clip-path 275ms ease;

                .ammenu-text {
                    &:first-letter {
                        text-transform: uppercase;
                    }
                }

                &:after {
                    display: none !important;
                }
            }
        }

        .ammenu-item.-parent {
            &:after {
                transition: 0.1s all ease-in;
                margin: 0 0 0 10px;
                width: 30px;
                height: 10px;
                background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTciIGhlaWdodD0iMTAiIHZpZXdCb3g9IjAgMCAxNyAxMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMTYuNDQxOSA1LjQ0MTk0QzE2LjY4NiA1LjE5Nzg2IDE2LjY4NiA0LjgwMjE0IDE2LjQ0MTkgNC41NTgwNkwxMi40NjQ1IDAuNTgwNTgzQzEyLjIyMDQgMC4zMzY1MDUgMTEuODI0NyAwLjMzNjUwNSAxMS41ODA2IDAuNTgwNTgzQzExLjMzNjUgMC44MjQ2NiAxMS4zMzY1IDEuMjIwMzkgMTEuNTgwNiAxLjQ2NDQ3TDE1LjExNjEgNUwxMS41ODA2IDguNTM1NTNDMTEuMzM2NSA4Ljc3OTYxIDExLjMzNjUgOS4xNzUzNCAxMS41ODA2IDkuNDE5NDJDMTEuODI0NyA5LjY2MzUgMTIuMjIwNCA5LjY2MzUgMTIuNDY0NSA5LjQxOTQyTDE2LjQ0MTkgNS40NDE5NFpNMCA1LjYyNUgxNlY0LjM3NUgwVjUuNjI1WiIgZmlsbD0iIzE3ODdFMCIvPjwvc3ZnPg==");
                background-position: center;
                background-repeat: no-repeat;
                content: "";
                opacity: 0;
            }
        }

        .ammenu-item.-active.-parent {
            &:after {
                transition: 0.1s all ease-in;
                opacity: 1;
                transform: translateX(5px);
            }
        }

        .ammenu-column {
            > .ammenu-link {
                margin: 0 0 10px;
                text-transform: uppercase;
                font-weight: 700;
                font-size: 14px;
                line-height: 25px;
                text-transform: uppercase;

                &:after {
                    display: none;
                }
            }
        }

        .ammenu-list {
            .ammenu-list {
                transition: 0.1s all ease-in;
                position: absolute;
                left: calc(100% + 20px);
                z-index: 9;
                margin: 0;
                padding: 10px;
                border: 1px solid transparent;
                border-radius: 2px;
                background: #fff;
                box-shadow: 0 2px 4px #575757 40;
            }
        }
    }

    &:not(.-hamburger) {
        .ammenu-main-container {
            .ammenu-items.-root {
                max-width: $layout__max-width;
                width: 100%;
                margin: 0 auto;
            }
        }
    }
}

.ammenu-nav-sections.-topmenu.-sticky {
    min-height: 58px;

    .ammenu-main-container {
        max-width: 100%;
        position: fixed;
        z-index: 9;
        top: 0;
        right: 0;
        left: 0;
        padding: 0;
        box-shadow: 0 0 20px #00000030;        
    }

    .ammenu-items.-root {
        max-width: $layout__max-width;
        width: 100%;
        margin: 0 auto;       
    }
}

.ammenu-submenu-block {
    box-sizing: border-box;
    background-position: center center;
    background-size: inherit;
    background-repeat: no-repeat;
    transition: 0.3s;

    .ambrands-link {
        display: none;
    }

    .ambrands-list-popup {
        position: relative;
        opacity: 1;
        pointer-events: auto;
    }

    .amreview-widget-container {
        .amreview-title {
            background: none !important;
        }

        .review-item {
            background: none !important;
        }
    }

    .ammenu-product-list.-slider {
        .slick-slide {
            vertical-align: top;
        }
    }

    .ammenu-product-list.-grid {
        margin: 0;
        padding: 0 10px;
    }

    .ammenu-product-list {
        .actions-secondary {
            .action {
                opacity: 0.7;

                &:hover {
                    opacity: 1;
                }
            }
        }
    }
}

.ammenu-submenu-block.-root {
    position: absolute;
    z-index: 999;
    overflow-y: auto;
    max-width: 100%;
    max-height: 70vh;
    box-shadow: 0 4px 5px rgba(35, 74, 87, 0.1);
    font-weight: 400;
    cursor: auto;
}

.ammenu-submenu-block.-root.-full {
    left: 0;
}

.ammenu-submenu-block.-simple {
    box-sizing: border-box;
    padding: 25px;
    background-position: center center;
    background-size: inherit;
    background-repeat: no-repeat;

    .ambrands-link {
        display: none;
    }

    .ambrands-list-popup {
        position: relative;
        opacity: 1;
        pointer-events: auto;
    }
}

.ammenu-nav-sections.-mobile.-accordion {
    .ammenu-toggle {
        transform: rotate(180deg);
    }

    .ammenu-toggle.-active {
        transform: rotate(0);
    }

    .ammenu-items.-root {
        > .ammenu-item {
            > .ammenu-items {
                > .ammenu-item {
                    > .ammenu-link {
                        /*text-transform: uppercase;
                        font-weight: 600;*/
                    }
                }
            }
        }
    }
}

.pagebuilder-content-type-wrapper {
    .ammenu-item {
        list-style: none;
    }
}

.ammenu-label-group.admin__control-grouped {
    display: flex;
    justify-content: space-between;
    align-items: center;

    > .admin__field {
        display: flex;
        align-items: center;
        flex-grow: 1;
        margin-top: 0;
    }

    .colorpicker-input {
        width: calc(100% - 37px) !important;
    }
}

.ammenu-items-tree {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    list-style: none;
    font-size: 2rem;

    .ammenu-item {
        flex-basis: 200px;
        box-sizing: border-box;
        margin-bottom: 20px;
        padding: 35px 30px;
        border: 1px dashed #ccc;
        border-radius: 4px;
        background: #fff;
        white-space: nowrap;
        cursor: pointer;

        &:hover {
            border-color: #a0a0a0;
            box-shadow: 0 2px 4px rgba(32, 77, 112, 0.16);
        }

        &:not(:last-child) {
            margin-right: 20px;
        }
    }
}

.ammenu-submenu-block.-builder.-root {
    display: flex;
    overflow-y: auto;
    scrollbar-color: #adadad rgba(173, 173, 173, 0.2);
    scrollbar-width: thin;
    padding: 25px;

    &::-webkit-scrollbar {
        background: #f0f0f0;
        width: 6px;
        height: 12px;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 6px;
        background: #adadad;
        cursor: pointer;
        width: 6px;
        height: 6px;
    }

    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px #fff;
        width: 6px;
        height: 6px;
    }

    .ammenu-items-list {
        display: flex;
        display: inline-flex;
        box-sizing: border-box;
        font-size: 1.4rem;

        .ammenu-item {
            position: relative;
            line-height: 20px;
            cursor: pointer;
        }
    }

    .ammenu-label {
        position: absolute;
        right: 0;
        z-index: 1;
        transform: translate(0, -18px);
    }

    .ammenu-link {
        position: relative;
        display: flex;

        .ammenu-text {
            overflow: hidden;
            max-width: 200px;
            text-align: left;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }

    .ammenu-submenu-sidebar {
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        align-self: stretch;
        box-sizing: border-box;
        max-width: 100%;
        width: 100%;
    }

    > .ammenu-submenu-block {
        position: relative;
    }

    .ammenu-submenu-block {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        align-content: flex-start;
        box-sizing: border-box;
        padding: 0;
        width: 100%;
    }

    .ammenu-content-block {
        flex-basis: 100%;
        margin: 0;
        padding: 0;
        max-width: 100%;
    }

    .ammenu-product-list {
        max-width: 100%;
        width: 100%;
    }

    .products-slider {
        max-width: 100%;
        width: 100%;
    }
}

.ammenu-submenu-block.-top {
    flex-wrap: wrap;

    > div {
        flex-basis: 100%;
        box-sizing: border-box;
    }

    > .ammenu-items-list {
        display: flex;
        padding: 8px 0;
        width: 100%;

        > .ammenu-item {
            margin: 0 15px 0 0;
        }
    }

    > .ammenu-submenu-sidebar {
        flex-basis: 100%;

        > .ammenu-submenu-block {
            &:not(.-empty) {
                position: relative;
                padding: 10px 0 0;

                &:after {
                    position: absolute;
                    display: inline-block;
                    content: "";
                    -ms-filter: contrast(0.1);
                    -webkit-filter: contrast(0.1);
                    filter: contrast(0.1);
                    top: 0;
                    right: 0;
                    left: 0;
                    width: 100%;
                    border-top: 1px solid #e9ecf5;
                }
            }
        }
    }
}

.ammenu-submenu-block.-top.-root {
    > .ammenu-items-list {
        .ammenu-label {
            white-space: pre;
        }
    }
}

.ammenu-submenu-block.-left {
    flex-wrap: nowrap;

    > .ammenu-items-list {
        display: inline-flex;
        flex-direction: column;
        padding: 0 15px 0 0;
        text-align: right;

        .ammenu-item {
            align-self: flex-start;
            margin: 0;
            padding: 8px 10px 8px 0;
            min-width: 90px;
            line-height: 1.3;
        }

        .ammenu-label {
            position: relative;
            max-width: 100px;
        }
    }

    > .ammenu-items-list + .ammenu-submenu-sidebar {
        position: relative;
        padding: 0 0 0 25px;

        > .ammenu-submenu-block {
            &:not(.-empty) {
                &:before {
                    position: absolute;
                    display: inline-block;
                    content: "";
                    -ms-filter: contrast(0.3);
                    -webkit-filter: contrast(0.3);
                    filter: contrast(0.3);
                    top: 0;
                    bottom: 0;
                    left: 0;
                    height: 100%;
                    border-left: 1px solid #e9ecf5;
                }
            }
        }
    }
}

.-ie {
    .ammenu-submenu-block {
        .ammenu-items-list {
            > .ammenu-item {
                min-height: 1em;
            }
        }
    }
}

.ammenu-nav-sections.-mobile.-drill {
    transition: 0.1s all ease-in;

    .ammenu-items.-drill {
        .ammenu-toggle {
            transform: rotate(90deg);
        }
    }

    .ammenu-button.-go-main {
        display: flex;
        align-items: center;
        padding: 12px 20px;
        width: 100%;
        border: none;
        border-radius: 0;
        background: #bb0339;
        box-shadow: none;
        color: #fff;
        text-align: left;
        font-weight: 400;

        &:before {
            margin: 0 15px 0 5px;
            width: 12px;
            content: "<<";
            font-size: 1rem;
            line-height: 1px;
            transform: scaleY(2);
        }
    }

    .-slide-left {
        animation: slide-left 0.1s forwards;
    }

    .-slide-right {
        animation: slide-right 0.1s forwards;
    }

    .ammenu-active-level {
        .ammenu-button.-prev {
            display: flex;
            justify-content: center;
            align-items: center;
            border: none;
            border-radius: 0;
            background: none;
            box-shadow: none;
            color: inherit;
            font-weight: inherit;
            margin: 0;
            padding: 0;
            width: 50px;
            height: 50px;

            .reset-button {
                border: none;
                border-radius: 0;
                background: none;
                box-shadow: none;
                color: inherit;
                font-weight: inherit;
            }

            &:focus {
                border: none;
                border-radius: 0;
                background: none;
                box-shadow: none;
                color: inherit;
                font-weight: inherit;
            }

            &:hover {
                border: none;
                border-radius: 0;
                background: none;
                box-shadow: none;
                color: inherit;
                font-weight: inherit;
            }

            .ammenu-icon {
                width: 20px;
                height: 20px;
                transform: rotate(180deg);
            }
        }

        .ammenu-item {
            display: flex;
            align-items: center;
            height: 50px;
            border-bottom: 1px solid #ccc;
        }

        .ammenu-head {
            display: flex;
            align-items: center;
            margin: 0;
            padding: 0 20px;
            border-bottom: 1px solid #9c9c9c;
            font-size: 1.6rem;
            cursor: pointer;

            .ammenu-link {
                padding: 0;
                /*text-transform: uppercase;
                font-weight: 600;*/
            }
        }

        .ammenu-link {
            /*text-transform: uppercase;
            font-weight: 600;*/
        }
    }
}
