//
//  Theme variables
//  _____________________________________________

//  Messages
$message-global-note__background           : $color-yellow-light2 !default;
$message-global-note__border-color         : $color-yellow-light3 !default;
$message-global-note__color                : $text__color !default;

$message-global-note-link__color           : $link__color !default;
$message-global-note-link__color-hover     : $link__hover__color !default;
$message-global-note-link__color-active    : $link__active__color !default;

$message-global-caution__background        : $color-red9 !default;
$message-global-caution__border-color      : none !default;
$message-global-caution__color             : $color-white !default;

$message-global-caution-link__color        : $link__color !default;
$message-global-caution-link__color-hover  : $link__hover__color !default;
$message-global-caution-link__color-active : $link__active__color !default;

//  Header
$header__background-color                  : false !default;
$header-icons-color                        : $color-gray56 !default;
$header-icons-color-hover                  : $color-gray20 !default;

$addto-color                               : $text__color__muted !default;
$addto-hover-color                         : $primary__color !default;

$minicart-icons-color                      : $header-icons-color !default;
$minicart-icons-color-hover                : $header-icons-color-hover !default;

$button__shadow                            : inset 0 2px 1px rgba(0, 0, 0, 0.12) !default;

$h1__margin-bottom__desktop                : $indent__xl !default;

//  Footer
$footer__background-color                  : false !default;


body {
    @include lib-css(background-color, $page__background-color);
}

//
//  Header
//  ---------------------------------------------

.page-header {
    @include lib-css(background-color, $header__background-color);
    /*margin-bottom: $indent__base;*/
    div.panel.wrapper {
        border: none;
        div.panel.header {
            width: auto;
            margin: 0;
            padding: 0;
            max-width: 100%;
            &::before {
                display: none;
            }
            ul.header.links {
                display: block;
                float: none;
                padding: 0;
                margin-bottom: 0;
                li.greet.welcome {
                    display: block;
                    margin: 0;
                    max-height: 42px;
                    min-height: 42px;
                    background-color: rgba(#DDA982, 0.5);
                    padding: 0;
                    line-height: 42px;
                    color: #000;
                    text-align: center;
                    font-size: 14px;
                    font-weight: 500;
                    overflow: hidden;
                }
            }
        }
    }
  .ammenu-logo {
      img {
        @include max-screen($screen__m) {
            width: auto;
        }
      }
  }  
}
ul.ammenu-items.-root {
	li.ammenu-item {
		&:not(.-level-1) {
			&:last-child {
				.ammenu-link {
					color: #bb033a !important;
				}
			}
		}
	}
}

.logo {
    margin: 0 0 $indent__s $indent__xl;
    max-width: 50%;
    position: relative;
    z-index: 5;

    img {
        display: block;
    }

    .page-print & {
        float: none;
    }
}

.page-main {
    > .page-title-wrapper {
        .page-title + .action {
            margin-top: $indent__l;
        }
    }
}

.action.skip {
    &:not(:focus) {
        @extend .abs-visually-hidden;
    }

    &:focus {
        @include lib-css(background, $color-gray94);
        @include lib-css(padding, $indent__s);
        box-sizing: border-box;
        left: 0;
        position: absolute;
        text-align: center;
        top: 0;
        width: 100%;
        z-index: 15;
    }
}

.action-skip-wrapper {
    height: 0;
    position: relative;
}

//
//  Global notice
//  ---------------------------------------------

.message.global {
    p {
        margin: 0;
    }

    &.noscript,
    &.cookie {
        @include lib-message($_message-type: global-note);
        margin: 0;
    }

    &.cookie {
        bottom: 0;
        left: 0;
        position: fixed;
        right: 0;
        z-index: 3;

        .actions {
            margin-top: $indent__s;
        }
    }

    &.demo {
        @include lib-message($_message-type: global-caution);
        margin-bottom: 0;
        text-align: center;
    }
}

//
//  Footer
//  ---------------------------------------------

.page-footer {
    @include lib-css(background-color, $footer__background-color);
    margin-top: auto;
}

.footer {
    background-color: $color-gray20;
    &.content {
        border-top: 1px solid $border-color__base;
        margin-top: 25px;
        padding-bottom: 25px;
        padding-top: 25px;

        ul {
            @extend .abs-reset-list;
        }

        .links {
            > li {
                margin: 0 0 8px;
            }
        }

        .switcher-store {
            margin: 0 0 30px;
        }
    }

    .copyright,
    .bugs {
        display: block;
        margin: 20px 0 0;
    }
}

.page-header,
.page-footer {
    .switcher {
        margin-right: 10px;

        .options {
            @include lib-dropdown(
                $_dropdown-actions-padding            : 0,
                $_dropdown-list-item-padding          : 0,
                $_dropdown-toggle-icon-content        : $icon-down,
                $_dropdown-toggle-active-icon-content : $icon-up,
                $_icon-font-text-hide                 : true,
                $_icon-font-size                      : 22px,
                $_icon-font-line-height               : 22px,
                $_dropdown-list-min-width             : 160px
            );

            ul.dropdown {
                a {
                    display: block;
                    padding: 8px;

                    &:hover {
                        text-decoration: none;
                    }
                }
            }
        }

        li {
            font-size: $font-size__s;
            margin: 0;
        }

        .label {
            @extend .abs-visually-hidden;
        }

        strong {
            font-weight: $font-weight__regular;
        }
    }
}

//
//  Widgets
//  ---------------------------------------------

.sidebar {
    .widget.block:not(:last-child),
    .widget:not(:last-child) {
        @extend .abs-margin-for-blocks-and-widgets;
    }
}

.widget {
    clear: both;

    .block-title {
        @extend .abs-block-title;
    }
}

.page-header,
.page-footer {
    .widget.block {
        @include lib-css(margin, $indent__base 0);
    }
}

.no-display {
    @extend .abs-no-display;
}

//
//  Calendar
//  ---------------------------------------------

.ui-datepicker {
    td {
        padding: 0;
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {

    html,
    body {
        height: 100%; // Stretch screen area for sticky footer
    }

    .page-wrapper {
        @include lib-vendor-prefix-display(flex);
        @include lib-vendor-prefix-flex-direction(column);
        min-height: 100%; // Stretch content area for sticky footer

        > .breadcrumbs,
        > .top-container,
        > .widget {
            box-sizing: border-box;
            width: 100%;
        }

        .ie10 &,
        .ie11 & {
            height: 100%;
        }
    }

    .navigation ul {
        padding: 0 8px;
    }

    .header {
        &.panel {
            > .header.links {
                font-size: 0;
                @include lib-list-inline();
                margin-left: auto;

                > li {
                    font-size: $font-size__base;

                    &.welcome {
                        a {
                            @include lib-css(padding-left, $indent__xs);
                        }
                    }
                }
            }
        }

        &.content {
            @extend .abs-add-clearfix-desktop;
            padding: 15px 0;
        }
    }

    .page-header {
        border: 0;
        margin-bottom: 0;

        .panel.wrapper {
            border-bottom: 1px solid $secondary__color;
        }

        .header.panel {
            @extend .abs-add-clearfix-desktop;
            padding-bottom: $indent__s;
            padding-top: $indent__s;
        }

        .switcher {
            display: inline-block;
        }
    }

    .page-main {
        > .page-title-wrapper {
            .page-title {
                display: inline-block;
            }

            .page-title + .action {
                float: right;
                margin-top: $indent__base;
            }
        }
    }

    .logo {
        margin: -8px auto 25px 0;

        img {
            max-height: inherit;
        }
    }

    .footer {
        &.content {
            .block {
                float: right;
            }

            .links {
                display: inline-block;
                margin-bottom: 20px;
                padding: 0 50px 0 0;
                vertical-align: top;
            }

            .switcher.store {
                display: inline-block;
                padding-right: 50px;
                vertical-align: top;
            }
        }

        .copyright {
            @extend .abs-add-clearfix-desktop;
        }
    }
}

//
//  Heading
//  _____________________________________________

h2.mp-product-slider-title, .block-title h3 {
    position: relative;
    margin: 80px 0 70px;
    font-weight: 600;
    font-size: 30px;
    line-height: 110%;
    text-align: center;
    text-transform: uppercase;
    color: #000000;
    @include max-screen($screen__m) {
        margin: 15px 0 30px;
    }
    &::after {
        position: absolute;
        content: '';
        bottom: -10px;
        left: 50%;
        width: 30px;
        height: 2px;
        margin-left: -15px;
        background-color: #DDA982;
    }
}
.promo-slider {
    h2.mp-product-slider-title {
        color: #BB0339;
        &::after {
            background: #B29783;
        }
    }
}

//
//  Promo bloc
//  _____________________________________________

.promo-bloc {
    background: #dda98240;
    border-radius: 3px;
    text-align: center;
    text-transform: uppercase;
    padding: 30px 10px 40px;
    margin-bottom: 40px;
    span {
        display: block;
        font-weight: 500;
    }
    .title {
        font-weight: bold;
        font-size: 64px;
        line-height: 70px;
        color: #000000;
    }
    .sub-title {
        font-weight: 600;
        font-size: 21px;
        margin-bottom: 10px;
    }
    a.btn {
        display: inline-block;
        margin-top: 10px;
        background: #000000;
        border: 1px solid #000000;
        border-radius: 3px;
        padding: 10px 20px;
        color: #ffffff;
        text-decoration: none;
        transition: all ease 0.3s;
        &:hover {
            color: #000000;
            background: #f8e9e0;
        }
    }
}

.featured-bloc {
    background: #fcf6f2;
    padding: 45px 0;
    margin-bottom: 35px;
    .page-main {
        > h3 {
            position: relative;
            margin: 10px 0 50px;
            font-weight: 600;
            font-size: 30px;
            line-height: 110%;
            text-align: center;
            text-transform: uppercase;
            color: #000000;
            &::after {
                position: absolute;
                content: '';
                bottom: -10px;
                left: 50%;
                width: 30px;
                height: 2px;
                margin-left: -15px;
                background-color: #000000;
            }
        }
    } 
    
}

.mpinstagramfeed-container .mpinstagramfeed-photo {
    margin: 2px;
    display: inline-block;
    position: relative;
}

@media (max-width: 767px) {
    .mpinstagramfeed-container .mpinstagramfeed-photo .mpinstagramfeed-post-caption {
        font-size: 14px !important;
        opacity: 1 !important;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .mpinstagramfeed-container .mpinstagramfeed-photo .mpinstagramfeed-post-caption {
        opacity: 1 !important;
    }
}

.mpinstagramfeed-container .mpinstagramfeed-photo .mpinstagramfeed-post-caption {
    line-height: 18px;
    letter-spacing: normal;
    position: absolute;
    bottom: 0;
    padding: 5px;
    opacity: 0;
    background-color: rgba(0, 0, 0, 0.3);
    color: #F0F0F0;
    max-height: 30%;
    overflow: hidden;
    text-overflow: ellipsis;
}

.mpinstagramfeed-photo:hover .mpinstagramfeed-post-caption {
    opacity: 1 !important;
    display: inline;
}

.mpinstagramfeed-container .mpinstagramfeed-photo img {
    display: block;
    width: 100%;
    max-width: none;
    height: 100%;
    object-fit: cover;
    padding: 0 1px 1px 0;
    max-height: 300px;
}
