.cms-nos-magasins {
    .block-main-content {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        padding: 20px 0;
        .first-store, .second-store {
            width: 50%;
            @media(max-width:426px) {
                width: 100%;
            }
            .stores-list-info {
                list-style: none;
                @media(max-width:426px) {
                    padding-inline-start: 0;
                }
                .store-title {
                    font-weight: 500;
                }
                .store-info {
                    display: inline;
                }
                .store-map-link {
                    font-weight: 500;
                    text-decoration: underline; 
                }
            }
        }
    }
}

.brand-images {
    .brands-letter {
        .bare-list {
            justify-content: center;
        }
    }
}


.a2a_mini {
    margin-top: 5px;
    margin-left: 5px;
}

.product-options-wrapper {
    margin-bottom: 22px;
}