.home-categories-container {
    position: relative;
    padding-bottom: 30px;
    margin-bottom: 40px;
    &::after {
        position: absolute;
        width: 100%;
        height: 70%;
        bottom: 0;
        content: '';
        background: #dda9821a;
        z-index: 1;
    }
}
h3.home-categories-title {
    position: relative;
    margin: 80px 0 70px;
    font-weight: 600;
    font-size: 30px;
    line-height: 110%;
    text-align: center;
    text-transform: uppercase;
    color: #000000;
    @include max-screen($screen__m) {
        margin: 15px 0;
    }
    &:after {
        position: absolute;
        content: '';
        top: 45px;
        left: 50%;
        width: 30px;
        height: 2px;
        margin-left: -15px;
        background-color: #DDA982;
    }
}

.masonry {
    position: relative;
    display: flex;
    width: 100%;
    z-index: 5;
    
    &.masonry--h {
        flex-flow: row wrap;
    }
    img {
        vertical-align: middle;
        max-width: 100%;
    }
    
    .masonry-brick {
        position: relative;
        flex: auto;
        height: 269px;
        min-width: 269px;
        overflow: hidden;
        border-radius: 3px;
        margin: 15px;
        background-color: #333;
        color: white;
        @include max-screen($screen__m) {
            margin: 7.5px 0;
        }        
        span.title {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 85%;
            z-index: 50;
            transform: translate(-50%, -50%);
            font-size: 25px;
            font-weight: 500;
            color: #ffffff;
            text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            text-align: center;
            text-transform: uppercase;
        }

        .masonry-img {
            object-fit: cover;
            width: 100%;
            height: 100%;
            filter: brightness(75%);
            transition: all ease 0.3s;
        }

        &:hover {
            span.title {

            }
            .masonry-img {
                transform: scale(1.1);
                filter: brightness(50%);
            }
        }

    }
}
@media only screen and (min-width: 1024px) {
    .masonry-brick--h:nth-child(1) {
        width: 579px;
    }
}

@media only screen and (max-width: 1023px) and (min-width: 768px) {
    .masonry-brick--h:nth-child(1) {
        width: 302px;
    }
}
