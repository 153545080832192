.page-title-wrapper {
    height: 100px;
    background: #f3e2d6;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 25px;
    .page-title {
        font-weight: 500;
        font-size: 25px;
        text-transform: uppercase;
        color: $color-black;
        margin-bottom: 0;
    }
}
.list.labels-badgets {
    position: absolute;
    top: 10px;  
    z-index: 99999;
    text-transform: uppercase;
    .new-label {
        background-color: $color-black;
        min-width: 90px;
        height: 28px;
        line-height: 28px;
        color: $color-white;
    }
    .promo-label {
        width: 70px;
        background-color: #BB0339;
        height: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $color-white;
        margin-bottom: 5px;
    }
}
.page-products {
    .column.main {
        .toolbar-products {
            border: 1px solid #e5e5e5;
            border-style: solid none;
            font-size: 14px;
            line-height: 45px;
            text-transform: capitalize;
            color: #000000;
            font-weight: 500;
            padding: 7px 0;
            .toolbar-sorter {
                display: none;
            }
        }
        select {
            height: auto;
        }
        .item.product.product-item {
            .product-item-info {
                @media (max-width: 426px) {
                    margin: 10px auto;
                }
            }
        }
        .products {
            .item.product.product-item {
                .product-item-info {
                    @media (max-width: 426px) {
                        margin: 10px auto;
                    }
                }
            }
        }
    }
    .sidebar-main {
        .block.filter {
            border-top: 0.8px solid #828282;
            .block-title.filter-title {
                margin: 20px 0;
                padding: 0 15px;
                @include max-screen($screen__s) {
                    display: none;
                }
            }
            .filter-title-mobile {
                margin: 20px 0;
                padding: 0 0 0 15px;
                font-size: 18px;
                @include min-screen($screen__s) {
                    display: none;
                }
                 &:after {
                    content: url(/media/catalog/down.png);
                    display: inline-block;
                    float: right;
                 }
            }
            .filter-title-mobile.active {
                &:after {
                   content: url(/media/catalog/up.png);
                   display: inline-block;
                   float: right;
                }
           }
            .block-content.filter-content {
                .filter-current {
                    margin-bottom: 34px;
                    .items {
                        .filter-item.remove {
                            display: flex;
                            justify-content: space-between;
                            border: 1px solid #828282;
                            border-radius: 3px;
                            align-items: center;
                            padding: 6px 0;
                            font-weight: 600;
                            text-transform: capitalize;
                            padding: 15px;
                            height: 50px;
                        }
                    }
                }
                .block-actions.filter-actions {
                    .action.clear.filter-clear {
                        font-weight: 500;
                        text-decoration-line: underline;
                        text-transform: uppercase;
                        color: #828282;
                        padding-left: 15px;
                    }
                }
            }
        }
        .filter-options {
            .filter-options-item {
                .filter-options-title {
                    &:after {
                        content: url(/media/catalog/down.png);
                        display: inline-block;
                        float: right;
                    }
                }
            }
            .filter-options-item.active {
                .filter-options-title {
                    &:after {
                        content: url(/media/catalog/up.png);
                        display: inline-block;
                        float: right;
                    }
                }
            }

            .filter-options-title {
                border-top: 0.5px solid #e0e0e0;
                font-size: 14px;
                line-height: 24px;
                color: #000000;
                padding: 18px 0;
                margin-bottom: 0;
                padding-left: 15px;
                cursor: pointer;
            }
            .filter-options-content {
                .swatch-attribute.swatch-layered.color {
                    .swatch-attribute-options {
                        .swatch-option {
                            border-radius: 50%;
                            height: 25px;
                            width: 25px;
                       }
                   }
               }
           }
       }
   }
}



.product-items {
    .product-item {
        .product-item-info {
            .actions-primary {
                button.action.primary{
                    border-radius: 0px;
                }
            }
        }
    }
}