.cms-mode-paiement, .cms-livraison, .cms-a-propos, .cms-generalites   {
    .mode-paiement-content, .livraison-content, .apropos-content, .generalites-content {
        font-size: 16px;
        line-height: 30px;
        text-align: justify;
        h3 {
            font-weight: 600;
        }
        .cart-info {
            list-style: none;
            padding-inline-start: 0;
        }
        .website-link {
            font-weight: 600;
            font-size: 18px;
        }
        .website-name {
            font-weight: bold;
        }
    }
}